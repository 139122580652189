import { JSON_GET, JSON_POST } from './axiosConfig';

// 获取角色权限
export function apiGetUserRole (params: any) {
  return JSON_GET('/ultra/api/manage/permission/role', params);
}

// 绑定角色权限
export function apiBindUserRole (params: any) {
  return JSON_POST('/ultra/api/manage/permission/bind', params, true, true);
}

// 获取角色权限树
export function apiGetUserRolePermissionTree (params?: any) {
  return JSON_GET('/ultra/api/v1/permission/tree', params);
}